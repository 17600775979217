/* eslint-disable */
export const DiscordEventGroup = {
  UserActivity: 'userActivity',
  RoomManagement: 'roomManagement'
};

const webhookGroupUrls = {
  [DiscordEventGroup.UserActivity]: process.env.VUE_APP_DISCORD_WEBHOOK_USER_ACTIVITY,
  [DiscordEventGroup.RoomManagement]: process.env.VUE_APP_DISCORD_WEBHOOK_ROOM_MANAGEMENT,
};

export const sendDiscordWebhook = async (eventGroup, eventDetail, roomId, users = {}, includeJoinLink = false) => {
  const domain = new URL(window.location.pathname, window.location.origin).href;
  const webhookUrl = webhookGroupUrls[eventGroup];

  if (!webhookUrl) {
    console.error(`Webhook URL for event group ${eventGroup} is not specified. Exiting the function.`);
    return;
  }

  const usernames = Object.values(users).map(user => user.username);
  const formattedUserList = usernames.map(name => `- ${name}`).join('\n');
  const userCount = usernames.length;
  const userSection = userCount > 0 ? `\n\nUsers (${userCount}):\n${formattedUserList}` : "";

  const joinLink = includeJoinLink ? `\n\nJoin here: ${domain}#/join/${roomId}` : "";

  const message = {
    content: `${eventDetail} the room **${roomId}**.${userSection}${joinLink}`,
  };

  await fetch(webhookUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
};

export default sendDiscordWebhook;
